import { formatTime } from '@/filters';
import { anagraficaService } from '@/modules/anagrafica/services/anagraficaService';
import { enteService } from '@/modules/enti/service/enteService';
import { store } from '@/store';
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({
  name: 'UserCard'
})
export default class extends Vue {
  @Prop({ required: true })
  user: user.summary;

  ente: ente.item = null;
  anagrafica: giornalisti.item = null;
  assenzeIngiustificate: number = 0;

  async created() {
    this.assenzeIngiustificate = 0;
    if (this.user?.anagraficaGiornalistaId) {
      this.assenzeIngiustificate = (await anagraficaService.Detail(this.user.anagraficaGiornalistaId))?.contatoreBlacklist ?? 0;
      this.anagrafica = await anagraficaService.Detail(this.user.anagraficaGiornalistaId);
      this.ente = await enteService.Detail(this.anagrafica.anagraficaEnteId);
    } else {
      if (this.user?.anagraficaEnteId) {
        this.ente = await enteService.Detail(this.user.anagraficaEnteId);
      }
    }
  }


}
