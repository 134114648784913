import { baseRestService } from "@/services/_base/baseRestService";
import settings from "@/settings";

class AlboIscrittiService extends baseRestService {
  constructor() {
    super();
    this.baseUrl = () => settings.baseUrl;
  }

  async Count(params?: alboIscritti.query): Promise<number> {
    return await this.Get('/api/albo-iscritti/count', params);
  }

  async List(params?: alboIscritti.query): Promise<alboIscritti.item[]> {
    return await this.Get('/api/albo-iscritti', params);
  }

  async Ordini(params?: ente.crog): Promise<ente.crog[]> {
    try {
      return await this.Get<ente.crog[]>(`/api/albo-iscritti/ordini`, params);
    } catch (error) {
      return [];
    }
  }
}

export const alboIscrittiService = new AlboIscrittiService();