import { Component, Prop, Vue } from 'vue-property-decorator';
import { alboIscrittiService } from './services/alboIscrittiService';
import { capitalizeFirstLetter, generateKey } from '@/utils/utils';
import { ElForm } from 'element-ui/types/form';
import settings from '@/settings';

@Component({})
export default class AlboIscritti extends Vue {
    @Prop({ required: false, default: null })
    ordine: string
    creating: boolean = true;
    loading: boolean = false;
    list: alboIscritti.item[] = [];
    ordini: { id: string, key: string, nome: string; descrizione: string }[] = [];
    fixedOrdine: { id: string; key: string; nome: string; descrizione: string } = null;

    searchFormIsValid: boolean = false;
    displayAdvancedSettings: boolean = false;

    pagination: { pageSizes: number[]; total: number } = {
        pageSizes: [10, 20, 40, 60],
        total: 0
    };

    query: alboIscritti.query = {
        anagraficaEnteId: null,
        search: null,
        cognome: null,
        nome: null,
        codiceFiscale: null,
        dataNascita: null,
        mail: null,
        pec: null,
        categoria: null,
        dataPrimaIscrizione: null,
        provvedimentiDisciplinari: null,
        dataProvvedimento: null,
        orderField: 'NomeCompleto',
        orderDescending: false,
        page: 1,
        take: 10
    };

    get formRules() {
        return {
            cognome: [
                { required: true, message: `Il campo cognome è un campo richiesto di almeno 2 caratteri`, trigger: 'change' },
                { min: 2, message: 'Inserisci minimo 2 caratteri per il cognome', trigger: 'change' }
            ]
        };
    }

    get categorie(): { key: string, value: string | number }[] {
        return [
            { key: "Professionista", value: "PRO" },
            { key: "Pubblicista", value: "PUB|PUB_ABI|PRA_PUB" },
        ]
    }

    get embeddedIframe(): boolean {
        return window.location !== window.parent.location;
    }

    get ancestorOrigins(): string {
        if (location.ancestorOrigins !== undefined) {
          return location.ancestorOrigins[0];
        }

        let parentWin = window;
        while (parentWin !== window.top) {
          if (parentWin.document.referrer && parentWin.parent === window.top) {
              return parentWin.document.referrer
          }
          // @ts-ignore
          parentWin = parentWin.parent;
        }
        return null;
    }
    
    get currentURL(): string {
        if (!this.embeddedIframe) return null;
        try {
            return new URL(this.ancestorOrigins).host ?? null;
        } catch (error) {
            console.error(error);
            return null;
        }
    }

    get authorizedAncestorOrigins(): boolean {
        if (!this.embeddedIframe) return true;
        return settings.appSetting.allowedAncestorOrigins.includes(this.currentURL);
    }


    async created() {
        this.creating = true;
        if (!this.authorizedAncestorOrigins) {
            this.creating = false;
            return;
        }

        this.ordini = (await alboIscrittiService.Ordini()).map(m => {
            const nome = m.nome.replace('ORG ', '');
            return {
                id: m.id,
                key: generateKey(nome),
                nome: nome,
                descrizione: m.descrizione
            }
        })

        if (!this.ordini || this.ordini.length <= 0) {
            this.creating = false;
            return;
        }

        if (!!this.ordine) {
            this.fixedOrdine = this.ordini.find(f => f.key === generateKey(this.ordine)) ?? null;
            if (this.fixedOrdine) {
                this.query.anagraficaEnteId = this.fixedOrdine.id;
            }
        }
        this.creating = false;
        await this.fetchData();
    }

    async fetchData() {
        if (!this.searchFormIsValid) {
            this.pagination.total = 0;
            this.list = [];
            return;
        }
        this.loading = true;
        this.pagination.total = await alboIscrittiService.Count(this.query);
        this.list = await alboIscrittiService.List(this.query);
        this.loading = false;
    }


    findOrdine(anagraficaEnteId: string) {
        return (this.ordini || []).find(f => f.id === anagraficaEnteId)?.nome;
    }

    onSortChange({ column, prop, order }) {
        this.query.orderField = capitalizeFirstLetter(prop);
        this.query.orderDescending = order === 'descending';
        this.fetchData();
    }

    onPaginationSizeChange(size) {
        this.query.take = size;
        this.query.page = 1;
        this.fetchData();
    }

    onPaginationPageChange(page) {
        this.query.page = page;
        this.fetchData();
    }

    onFilterApply() {
        (this.$refs.alboIscrittiQueryForm as ElForm).validate(async valid => {
            this.searchFormIsValid = valid;
            if (valid) {
                this.query.page = 1;
                this.fetchData();
            }
        });
    }

    async onFilterClean() {
        this.query.anagraficaEnteId = null;
        this.query.search = null;
        this.query.cognome = null;
        this.query.nome = null;
        this.query.codiceFiscale = null;
        this.query.dataNascita = null;
        this.query.mail = null;
        this.query.pec = null;
        this.query.categoria = null;
        this.query.dataPrimaIscrizione = null;
        this.query.provvedimentiDisciplinari = null;
        this.query.dataProvvedimento = null;

        (this.$refs.alboIscrittiQueryForm as ElForm).validate(async valid => {
            this.searchFormIsValid = valid;
            this.$nextTick(() => {
                (this.$refs.alboIscrittiQueryForm as ElForm).clearValidate();
            });
            await this.fetchData();
        });
    }
}
