import { Component, Vue, Watch } from 'vue-property-decorator';
import { store } from '@/store';
import { userService } from '@/modules/users/services/userService';
import UserCard from './components/user-card.vue';
import CreditiGiornalistaOverview from '@/modules/offertaFormativa/components/crediti-giornalista-overview.vue'
import CreditiSpecialiOverview from '@/modules//creditiSpeciali/components/creditiSpecialiOverview/creditiSpecialiOverview.vue';
import PartecipanteAiCorsiOverview from '@/modules/giornalisti/components/partecipanteAiCorsi/PartecipanteAiCorsi.vue'
import EsenzioniGiornalistaOverview from "@/modules/offertaFormativa/components/Esenzioni/esenzioni-giornalista-overview.vue";
import viewAbsences from "@/modules/profile/components/view-absences.vue";
import { anagraficaService } from '@/modules/anagrafica/services/anagraficaService';

@Component({
  name: 'Profile',
  components: {
    UserCard,
    CreditiGiornalistaOverview,
    CreditiSpecialiOverview,
    PartecipanteAiCorsiOverview,
    EsenzioniGiornalistaOverview,
    viewAbsences
  }
})
export default class extends Vue {
  private activeTab = 'crediti';
  user: user.item = null;

  async created() {
    if(store.state.login.userInfo){
      this.user =  await userService.Detail(store.state.login.userInfo.id);
    }
  }

  get userInfo() {
    return store.state.login.userInfo;
  }

  @Watch("userInfo")
  async userInfoChange(n, o) {
    if (n && n !== o)
      this.user =  await userService.Detail(n.id);
  }
}
